import { addDays } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';

import { timeOfDay } from '@/lib/utils/time/timeOfDay';
import { UnavailablePeriod } from '@/types/core/UnavailablePeriod';

const buildSplitPeriod = ({
  period,
  offset,
  timeZone,
  minHour,
  maxHour,
}: Params & { offset: number }) => {
  const startOn = formatInTimeZone(period.startsAt, timeZone, 'yyyy-MM-dd');
  const startsAt = addDays(
    fromZonedTime(`${startOn}T${timeOfDay(minHour)}:00`, timeZone),
    offset,
  );
  const endsAt = addDays(
    fromZonedTime(`${startOn}T${timeOfDay(maxHour)}:00`, timeZone),
    offset,
  );

  return {
    id: `${period.id}-${offset}`,
    startsAt: startsAt.toISOString(),
    endsAt: endsAt.toISOString(),
    userId: period.userId,
    reason: period.reason,
  };
};

type Params = {
  period: UnavailablePeriod;
  timeZone: string;
  minHour: number;
  maxHour: number;
};

export const splitUnavailablePeriod = ({
  period,
  timeZone,
  minHour,
  maxHour,
}: Params) => {
  const startOn = formatInTimeZone(period.startsAt, timeZone, 'yyyy-MM-dd');
  const endOn = formatInTimeZone(period.endsAt, timeZone, 'yyyy-MM-dd');

  if (startOn !== endOn) {
    const start = new Date(startOn);
    const end = new Date(endOn);
    const days = Math.ceil(
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
    );

    return Array.from({ length: days }, (_, i) =>
      buildSplitPeriod({ period, offset: i, timeZone, minHour, maxHour }),
    );
  }
  return [period];
};
