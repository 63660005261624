export enum VisitTypeSlug {
  Any = '',
  Initial = 'initial',
  Return = 'return',
  Transition = 'transition',
}

export type VisitType = {
  durationMinutes: number;
  startInterval: number;
};
