export enum UserRole {
  Advisor = 'advisor',
  Admin = 'admin',
  Superadmin = 'superadmin',
  MD = 'md',
  RD = 'rd',
  PA = 'pa',
  CareCoordinator = 'pcc',
  NP = 'np',
  MedicalAssistant = 'ma',
  PharmacyTechnician = 'pharma',
}

export type ProviderRole =
  | UserRole.MD
  | UserRole.RD
  | UserRole.PA
  | UserRole.NP;
