globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"01285f07e9567f388650adba979cd0c2e5faa13a"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://fb9f2954a0be4e29b76aaa34f53b25a4@o4504544962805760.ingest.sentry.io/4504544963657728',

  enabled: DEPLOY_ENV ? DEPLOY_ENV !== 'development' : false,
  environment: DEPLOY_ENV || 'development',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: DEPLOY_ENV === 'production' ? 0.1 : 1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
