import '@/styles/globals.css';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useSentryIdentification } from '@/lib/hooks/useSentryIdentification';
import { fonts } from '@/ui/fonts';
import Layout from '@/ui/layout';

export type MantlePage<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type MantleAppProps = AppProps & {
  Component: MantlePage;
};

const defaultLayout = (page: ReactElement) => <Layout>{page}</Layout>;

function SentryIdentifier() {
  useSentryIdentification();
  return null;
}

const MantleApp = ({ Component, pageProps }: MantleAppProps) => {
  const [queryClient] = useState(() => new QueryClient());
  const getLayout = Component.getLayout ?? defaultLayout;

  return (
    <QueryClientProvider client={queryClient}>
      <SentryIdentifier />
      <div className={fonts.className}>
        {getLayout(<Component {...pageProps} />)}
        <ReactQueryDevtools initialIsOpen={false} />
      </div>
    </QueryClientProvider>
  );
};

export default MantleApp;
