import clsx from 'clsx';

export enum BadgeColor {
  Yellow = 'yellow',
  Blue = 'blue',
  Green = 'green',
  Gray = 'gray',
  Red = 'red',
  Orange = 'orange',
}

type BadgeProps = {
  color: BadgeColor;
  truncate?: boolean;
  title?: string;
  children: React.ReactNode;
  size?: 'sm' | 'lg';
};

export const Badge = ({
  color = BadgeColor.Yellow,
  truncate,
  title,
  children,
  size = 'sm',
}: BadgeProps) => {
  const className = clsx(
    'inline-flex items-center whitespace-nowrap rounded-full text-center text-xs font-medium',
    {
      'max-w-full': truncate,
      'text-black bg-yellow-200': color === BadgeColor.Yellow,
      'text-black bg-blue-200': color === BadgeColor.Blue,
      'text-black bg-green-200': color === BadgeColor.Green,
      'text-black bg-gray-200': color === BadgeColor.Gray,
      'text-black bg-orange-200': color === BadgeColor.Orange,
      'text-red-700 bg-red-200': color === BadgeColor.Red,
      'px-2.5 py-0.5': size === 'sm',
      'px-3 py-1': size === 'lg',
    },
  );

  return (
    <div className={className}>
      <div
        className={clsx('block', {
          truncate,
          'cursor-help': title && title.length > 0,
        })}
        title={title}
      >
        {children}
      </div>
    </div>
  );
};
