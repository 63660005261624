import { useMutation, useQueryClient } from 'react-query';

import { dismissNotifications } from '../api/core/dismissNotifications';

export const useDismissNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: dismissNotifications,
    onSuccess: () => queryClient.invalidateQueries('notifications'),
  });
};
