import { UserRole } from '@/types/core/UserRole';

const ROLE_TITLE: Record<UserRole, string> = {
  [UserRole.Admin]: 'Administrator',
  [UserRole.Superadmin]: 'Administrator',
  [UserRole.MD]: 'Physician',
  [UserRole.RD]: 'Registered Dietitian',
  [UserRole.PA]: 'Physician Assistant',
  [UserRole.CareCoordinator]: 'Care Coordinator',
  [UserRole.Advisor]: 'Advisor',
  [UserRole.NP]: 'Nurse Practitioner',
  [UserRole.MedicalAssistant]: 'Medical Assistant',
  [UserRole.PharmacyTechnician]: 'Pharmacy Tech',
};

export const roleTitle = (role: UserRole) =>
  ROLE_TITLE[role] || (role as string);
