import { AppointmentType } from '@/types/core/AvailabilityRule';
import { VisitTypeSlug } from '@/types/core/VisitType';

export const localizeAppointmentType = (
  appointmentType?: AppointmentType | VisitTypeSlug | null,
) => {
  switch (appointmentType) {
    case VisitTypeSlug.Initial:
      return 'Initial Visits';
    case VisitTypeSlug.Return:
      return 'Return Visits';
    case VisitTypeSlug.Any:
    case null:
    case undefined:
      return 'Any Visit';
    default:
      return appointmentType;
  }
};
