import AsyncSelect from 'react-select/async';

import { postCore } from '@/lib/api/core/client';
import { usePatient } from '@/lib/queries/usePatient';
import { formatBirthdate } from '@/lib/utils';
import { StatusBadge } from '@/ui/StatusBadge';

const search = async (q) => {
  const data = await postCore('search', { q });
  return data.map((patient) => ({
    value: patient.id,
    label: formatPatientName(patient),
    patient,
  }));
};

const formatPatientName = ({ firstName, legalName, lastName }) => {
  if (firstName !== legalName) {
    return `${legalName} "${firstName}" ${lastName}`;
  } else {
    return `${firstName} ${lastName}`;
  }
};

const CustomOption = ({ innerProps, isDisabled, data: { patient } }) => {
  return !isDisabled ? (
    <div
      {...innerProps}
      className="w-full cursor-pointer px-2 py-1 transition hover:bg-gray-100"
    >
      <div className="flex flex-row gap-4">
        <div className="flex grow flex-col">
          <div className="font-bold text-gray-700">
            {formatPatientName(patient)}
          </div>
          <div className="font-medium text-gray-400">
            {formatBirthdate(patient.birthdate)}
          </div>
        </div>
        <div className="flex shrink-0">
          <div>
            <StatusBadge status={patient.status} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export const PatientSearch = ({ onSelect, ...props }) => {
  const { selected } = props;
  const { data: patient } = usePatient(selected);

  return (
    <AsyncSelect
      id="patient-search"
      isClearable
      loadOptions={search}
      value={
        selected && patient
          ? { value: selected, label: formatPatientName(patient) }
          : undefined
      }
      onChange={onSelect}
      placeholder="Search"
      components={{
        Option: CustomOption,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base) => ({
          ...base,
          border: 0,
          borderWidth: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }),
        menu: (base) => ({
          ...base,
          width: 'max-content',
          minWidth: '300px',
        }),
      }}
      className="w-full"
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
      })}
      {...props}
    />
  );
};
