import { useMemo, useState } from 'react';
import Confetti from 'react-dom-confetti';

import { ConfettiContext } from './ConfettiContext';

const CONFETTI_CONFIG = {
  angle: 90,
  spread: 45,
  startVelocity: 50,
  elementCount: 200,
  dragFriction: 0.12,
  duration: 1500,
  stagger: 1,
  width: '8px',
  height: '8px',
  perspective: '600px',
};

type Props = {
  disabled?: boolean;
  children: React.ReactNode;
};

export const ConfettiProvider = ({ disabled, children }: Props) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiConfig, setConfettiConfig] = useState(CONFETTI_CONFIG);

  const confettiValue = useMemo(
    () => ({
      isActive: showConfetti,
      trigger: (config?: Record<string, unknown>) => {
        setConfettiConfig(
          config ? { ...CONFETTI_CONFIG, ...config } : CONFETTI_CONFIG,
        );

        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 0);
      },
    }),
    [showConfetti],
  );

  return (
    <ConfettiContext.Provider value={confettiValue}>
      {children}
      <div className="absolute bottom-0 left-1/2 z-50">
        <Confetti active={showConfetti && !disabled} config={confettiConfig} />
      </div>
    </ConfettiContext.Provider>
  );
};
